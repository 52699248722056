import { DocumentCategory } from "models/Document";
import { get, post } from "./base";

export const getInvestorDocuments = (category: DocumentCategory) => {
  return get("/me/apex/investor_documents", { category });
};

export const uploadDocument = (file: File) => {
  const formData = new FormData();
  formData.append("data", file);

  return post(`/me/apex/documents/suki/upload`, true, formData, {
    "Content-Type": "multipart/form-data",
  });
};
