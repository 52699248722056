import axios from "axios";
import config from "config";
import { from } from "rxjs";
import { getCookie } from "typescript-cookie";
import { AUTH_COOKIE } from "utils/constants";
import history from "utils/history";

const AangAPI = axios.create({ baseURL: config.data });

export enum ErrorType {
  NotAuthenticated = "not authenticated",
  InvalidToken = "invalid token",
}

export const getAuthToken = (): string => {
  const token = getCookie(AUTH_COOKIE);

  if (!token) {
    window.open("/login", "_self");
    throw new Error(ErrorType.NotAuthenticated);
  }

  return token || "";
};

export const get = (route: string, params?: { [key: string]: any }) => {
  const token = getAuthToken();

  return from(
    AangAPI.get(route, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const post = (
  route: string,
  authed: boolean = true,
  body?: { [key: string]: any },
  headers?: { [key: string]: any }
) => {
  let token;
  if (authed) {
    token = getAuthToken();
  }

  return from(
    AangAPI.post(route, body, {
      headers: authed
        ? {
            Authorization: `Bearer ${token}`,
            ...(headers || {}),
          }
        : headers || {},
    })
  );
};
