import { Button } from "@mui/material";

interface IProps {
  onSelect: (file: File) => void;
  acceptType?: string;
  label?: string;
  disabled?: boolean;
}

const SelectFileButton = ({
  onSelect,
  acceptType,
  label = "Select File",
  disabled,
}: IProps) => {
  return (
    <>
      <input
        id={"file-form"}
        type="file"
        accept={acceptType}
        onChange={(evt) => {
          if (evt.target.files != null) {
            const file = evt.target.files[0];
            onSelect(file);
          }
        }}
        style={{ display: "none" }}
      />
      <label htmlFor={"file-form"}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={disabled}
        >
          {label}
        </Button>
      </label>
    </>
  );
};

export default SelectFileButton;
