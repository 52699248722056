import { CircularProgress } from "@material-ui/core";
import { Box, Typography, Button } from "@mui/material";
import SelectFileButton from "components/Buttons/SelectFileButton";
import useAuth from "components/Hooks/Auth.hooks";
import Spacer from "components/Spacer";
import React from "react";
import useDocumentUploadPage from "./DocumentUploadPage.hooks";

const DocumentUploadPage = () => {
  useAuth();
  const { onFileSelect, file, onBack, onUpload, uploading, success } =
    useDocumentUploadPage();

  return (
    <Box
      padding="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <Box
        maxWidth={600}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flex={1}
      >
        <img src="/scout.svg" />
        <Spacer height={40} />
        {!file && <SelectFileButton onSelect={onFileSelect} />}
        {file && !success && (
          <>
            <Typography variant="body1" color="white">
              {file.name}
            </Typography>
            <Spacer height={40} />
            <Box display="flex" flexDirection="row" width="100%">
              <Button
                variant="text"
                style={{
                  color: "#FFF",
                  width: "100%",
                  opacity: uploading ? 0.5 : 1,
                }}
                onClick={() => onBack()}
                size="large"
                disabled={uploading}
              >
                Back
              </Button>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="outlined"
                  style={{
                    color: "#FFF",
                    width: "100%",
                    opacity: uploading ? 0.5 : 1,
                  }}
                  onClick={() => onUpload()}
                  size="large"
                  disabled={uploading}
                >
                  Upload
                </Button>
                {uploading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
        {success && (
          <Typography variant="body1" color="white">
            Document has been successfully uploaded!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DocumentUploadPage;
