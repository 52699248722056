import { Button, makeStyles, TextField } from "@material-ui/core";
import { Box } from "@mui/material";
import ErrorContext from "components/Contexts/ErrorContext";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import Spacer from "components/Spacer";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import useLoginPage from "./LoginPage.hooks";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#FFF",
    borderRadius: 10,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#AEAEAE !important",
    color: "#FFF",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const {
    phone,
    setPhone,
    error,
    setError,
    requestCode,
    verifying,
    onBack,
    verifyCode,
    code,
    setCode,
  } = useLoginPage();

  let body = (
    <>
      {" "}
      <ErrorContext.Provider value={error || ""}>
        <PhoneInput
          country="US"
          placeholder="Enter phone number"
          value={phone}
          countries={["US"]}
          defaultCountry="US"
          onChange={(value) => {
            if (error) {
              setError("");
            }
            setPhone(value || "");
          }}
          inputComponent={PhoneNumberInput}
          style={{
            width: "100%",
          }}
        />
      </ErrorContext.Provider>
      <Spacer height={20} />
      <Button
        variant="text"
        style={{
          color: "#FFF",
          width: "100%",
        }}
        onClick={() => requestCode(phone)}
        size="large"
      >
        Log In
      </Button>
    </>
  );
  if (verifying) {
    body = (
      <>
        <TextField
          InputProps={{
            classes: {
              input: classes.input,
              root: classes.input,
              notchedOutline: classes.notchedOutline,
            },
          }}
          fullWidth
          label="Verification Code"
          variant="outlined"
          name="code"
          type="number"
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
        <Spacer height={20} />
        <Box display="flex" flexDirection="row" width="100%">
          <Button
            variant="text"
            style={{
              color: "#FFF",
              width: "100%",
            }}
            onClick={() => onBack()}
            size="large"
          >
            Back
          </Button>
          <Button
            variant="outlined"
            style={{
              color: "#FFF",
              width: "100%",
            }}
            onClick={() => verifyCode(code)}
            size="large"
          >
            Verify Code
          </Button>
        </Box>
      </>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      flexDirection="column"
      maxWidth={600}
      minWidth={400}
      margin="auto"
    >
      <img src="/scout.svg" />
      <Spacer height={40} />
      {body}
    </Box>
  );
};

export default LoginPage;
