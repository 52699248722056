import { getInvestorDocuments } from "api/apex";
import { DocumentCategory, InvestorDocument } from "models/Document";
import React, { useCallback, useEffect, useState } from "react";
import { catchError, of, zip } from "rxjs";
import { flatten } from "lodash";

const useDocumentsPage = () => {
  const [documents, setDocuments] = useState<InvestorDocument[]>([]);

  const fetchDocuments = useCallback(() => {
    zip([
      getInvestorDocuments(DocumentCategory.Statement).pipe(
        catchError(() => of({ data: [] }))
      ),
      getInvestorDocuments(DocumentCategory.TradeConfirmation).pipe(
        catchError(() => of({ data: [] }))
      ),
      getInvestorDocuments(DocumentCategory.TaxForm).pipe(
        catchError(() => of({ data: [] }))
      ),
    ]).subscribe((resp) => {
      setDocuments(flatten(resp.map(({ data }) => data)));
    });
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, []);

  return { documents };
};

export default useDocumentsPage;
