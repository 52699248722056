export interface InvestorDocument {
  title: string;
  subtitle: string;
  documentURL: string;
  documentId: string;
}

export enum DocumentCategory {
  Statement = "STATEMENT",
  TaxForm = "TAX_FORM",
  TradeConfirmation = "TRADE_CONFIRMATION",
}
