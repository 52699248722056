import { authenticate, requestAuthCode } from "api/user";
import { useEffect, useState } from "react";
import { getCookie } from "typescript-cookie";
import { AUTH_COOKIE } from "utils/constants";

const useLoginPage = () => {
  const [phone, setPhone] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string>();
  const [verifying, setVerifying] = useState(false);
  const [unixTime] = useState(Date.now());

  useEffect(() => {
    const token = getCookie(AUTH_COOKIE);

    if (token) {
      window.open("/documents", "_self");
    }
  }, []);

  const requestCode = (phoneNumber: string) => {
    requestAuthCode(phoneNumber, unixTime).subscribe(() => setVerifying(true));
  };

  const onBack = () => setVerifying(false);

  const verifyCode = (code: string) => {
    authenticate(phone, unixTime, code);
  };

  return {
    setPhone,
    phone,
    error,
    verifying,
    setError,
    requestCode,
    onBack,
    verifyCode,
    code,
    setCode,
  };
};

export default useLoginPage;