import React, { useEffect } from "react";
import "./App.css";
import { Box, createTheme, ThemeProvider } from "@material-ui/core";
import Routes from "routes";
import { initializeAmplitude } from "utils/analytics";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFF",
    },
    secondary: {
      main: "#FFF",
    },
  },
  typography: {
    allVariants: {
      color: "#FFF",
    },
  },
});

function App() {
  useEffect(() => {
    initializeAmplitude();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#0C0026",
          overflow: "auto",
        }}
        className="app"
      >
        <Routes />
      </Box>
    </ThemeProvider>
  );
}

export default App;
