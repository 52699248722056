import amplitude, { AmplitudeClient } from "amplitude-js";

let tracker: AmplitudeClient;

let queue: AmplitudeEvent[] = [];

interface AmplitudeEvent {
  eventName: string;
  data: any;
}

export enum AnalyticEvent {
  ScreenViewed = "screen_viewed",
  ButtonPressed = "button_pressed",
  ActionSucceeded = "action_succeeded",
  ActionStarted = "action_started",
}

export const initializeAmplitude = (userId?: string) => {
  const key = process.env.REACT_APP_AMPLITUDE_KEY;
  if (!key) {
    return;
  }

  amplitude.getInstance("suki").init(key, userId, undefined, (client) => {
    tracker = client;
    queue.forEach(({ eventName, data }) => logEvent(eventName, data));
    queue = [];
  });
};

export const logEvent = (eventName: string, data: any) => {
  if (!tracker) {
    console.error("Tracker not initialized", eventName);
    queue.push({
      eventName,
      data,
    });
    return;
  }

  tracker.logEvent(eventName, data);
};

let _currScreen = "";

export const logScreenViewed = (screen: string, data: any = {}) => {
  _currScreen = screen;
  logEvent(AnalyticEvent.ScreenViewed, { ...data, screen_name: screen });
};

export const logButtonPressed = (button: string, data: any = {}) => {
  logEvent(AnalyticEvent.ButtonPressed, {
    ...data,
    button_name: button,
    screen_name: _currScreen,
  });
};

export const logActionSucceeded = (action: string, data: any = {}) => {
  logEvent(AnalyticEvent.ActionSucceeded, {
    ...data,
    action_name: action,
    screen_name: _currScreen,
  });
};

export const logActionStarted = (action: string, data: any = {}) => {
  logEvent(AnalyticEvent.ActionStarted, {
    ...data,
    action_name: action,
    screen_name: _currScreen,
  });
};
