import { Box, Button, makeStyles } from "@material-ui/core";
import { getShare, Share, signupForWaitlist } from "api/share";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { logButtonPressed, logScreenViewed } from "utils/analytics";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import "react-phone-number-input/style.css";
import ErrorContext from "components/Contexts/ErrorContext";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  fullWidth: { width: "100%" },
  page: {
    flexDirection: "row",
    padding: "100px",
    alignItems: "flex-start",
    justifyContent: "center",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "40px 0 0 0",
    },
  },
  rightContainer: {
    maxWidth: "700px",
    flex: 1,
    marginLeft: "50px",
    marginTop: "80px",
    borderRadius: 20,
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
      marginTop: "40px",
      padding: "0 24px 32px 24px",
    },
  },
  video: {
    borderRadius: 20,
    width: "100%",
  },
  leftContainer: {
    width: "370px",
    marginRight: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      padding: "0 24px 0 24px",
    },
  },
  heading1: {
    fontSize: "38px",
    fontWeight: "bold",
  },
  body: {
    fontSize: "17px",
    fontWeight: 400,
  },
  input: {
    color: "#FFF",
    borderRadius: 10,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#AEAEAE !important",
    color: "#FFF",
  },
  button: {
    backgroundColor: "#666666",
    color: "#fff",
    borderRadius: 30,
    height: 50,
    "&:hover": {
      backgroundColor: "#666666",
    },
  },
  overlay: {
    position: "absolute",
    fontSize: "24px",
    fontWeight: 600,
    top: 0,
    left: 0,
  },
}));

const WaitlistPage = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const shareId = (match.params as any).id;
  const [share, setShare] = useState<Share>();
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<string>();

  useEffect(() => {
    logScreenViewed("waitlist", { share_id: shareId });
    getShare(shareId).subscribe(({ data }) => setShare(data));
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={(share?.collection || {}).title || "Join the waitlist"} />
        <meta property="og:type" content="video.movie" />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_SUKI_HOST}/waitlist/${shareId}`}
        />
        <meta property="og:video" content="https://youtube.com/v/4CTuYrQjbrk" />
      </Helmet>
      <Box
        display="flex"
        className={classes.page}
        width="100%"
        boxSizing="border-box"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.leftContainer}
          boxSizing="border-box"
        >
          <Box width="120px">
            <img src="/logo.png" className={classes.fullWidth} />
          </Box>
          <Box className={classes.heading1} textAlign="center" marginTop="50px">
            {(share?.collection || {}).title || "Join the waitlist"}
          </Box>
          <Box className={classes.body} textAlign="center" marginTop="16px">
            Add your phone number to join the waitlist for the Elevate app and
            unlock this video.
          </Box>
          <Box marginTop="32px" width="100%">
            <ErrorContext.Provider value={error || ""}>
              <PhoneInput
                country="US"
                placeholder="Enter phone number"
                value={phone}
                onChange={(value) => {
                  if (error) {
                    setError("");
                  }
                  setPhone(value || "");
                }}
                inputComponent={PhoneNumberInput}
              />
            </ErrorContext.Provider>
          </Box>
          <Box fontSize="10px" color="#757575" marginTop="8px">
            By joining, you agree to receive automated promotional messages. You
            may receive up to 3 text msgs/mo. Reply STOP to stop. Message and
            data rates may apply.
          </Box>
          <Box marginTop="28px" width="100%">
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              onClick={() => {
                if (isValidPhoneNumber(phone)) {
                  logButtonPressed("sign_up", {
                    phone_number: phone,
                    share_id: shareId,
                  });
                  signupForWaitlist(shareId, phone!).subscribe(() => {
                    if (share?.videoUrl) {
                      window.location.href = share?.videoUrl;
                    }
                  });
                } else {
                  setError("invalid phone number");
                }
              }}
            >
              Unlock the video
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.rightContainer}
          boxSizing="border-box"
        >
          <Box
            boxSizing="border-box"
            position="relative"
            borderRadius={20}
            overflow="hidden"
          >
            <video
              src={`${share?.videoUrl}#t=0.001`}
              className={classes.video}
            />
            <Box
              className={classes.overlay}
              bgcolor="rgba(51, 51, 51, .8)"
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <img src="/lock.svg" style={{ height: 50, fill: "white" }} />
              <Box marginTop="8px">Join the waitlist to unlock</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WaitlistPage;
