import { Box, Typography, Button, Link } from "@mui/material";
import useAuth from "components/Hooks/Auth.hooks";
import Spacer from "components/Spacer";
import React from "react";
import useDocumentsPage from "./DocumentsPage.hooks";

const columns = [
  {
    field: "title",
    headerName: "Title",
    flex: 1,
  },
  {
    field: "subtitle",
    headerName: "Description",
    width: 400,
  },
];

const DocumentsPage = () => {
  useAuth();
  const { documents } = useDocumentsPage();

  return (
    <Box
      padding="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <img src="/scout.svg" />
      <Spacer height={20} />
      <Link href="/upload" style={{ cursor: "pointer", color: "white" }}>
        <Typography variant="body2" color="white">
          Click here to upload a document.
        </Typography>
      </Link>
      <Spacer height={40} />
      {documents.map((document) => (
        <Box
          display="flex"
          flexDirection="row"
          maxWidth={400}
          minWidth={200}
          width="100%"
          style={{ color: "#fff" }}
          alignItems="center"
          padding="12px 0"
          key={document.documentId}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            flex={1}
          >
            <Typography variant="body1" fontWeight={700}>
              {document.title}
            </Typography>
            {document.subtitle && (
              <Typography variant="body2">{document.subtitle}</Typography>
            )}
          </Box>
          <Button
            variant="outlined"
            style={{ color: "#fff", borderColor: "#fff", borderRadius: 50 }}
            onClick={() => {
              window.open(document.documentURL, "_blank");
            }}
          >
            Download
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default DocumentsPage;
