import React, { forwardRef, useContext } from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ErrorContext from "components/Contexts/ErrorContext";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#FFF",
    borderRadius: 10,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#AEAEAE !important",
    color: "#FFF",
  },
}));

const PhoneInput = (props: any, ref: any) => {
  const classes = useStyles();
  const error = useContext(ErrorContext);

  return (
    <TextField
      {...props}
      error={!!error}
      helperText={error || undefined}
      InputProps={{
        classes: {
          input: classes.input,
          root: classes.input,
          notchedOutline: classes.notchedOutline,
        },
      }}
      inputRef={ref}
      fullWidth
      label="Phone Number"
      variant="outlined"
      name="phone"
    />
  );
};

export default forwardRef(PhoneInput);
