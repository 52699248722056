import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import DocumentsPage from "views/DocumentsPage/DocumentsPage";
import DocumentUploadPage from "views/DocumentUploadPage/DocumentUploadPage";
import LoginPage from "views/LoginPage/LoginPage";
import WaitlistPage from "views/WaitlistPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          key="new-course"
          exact
          path="/waitlist/:id"
          component={WaitlistPage}
        />
        <Route key="login" path="/login" component={LoginPage} />
        <Route key="docs" exact path="/documents" component={DocumentsPage} />
        <Route
          key="upload"
          exact
          path="/upload"
          component={DocumentUploadPage}
        />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
