import React, { useEffect } from "react";
import { getCookie } from "typescript-cookie";
import { AUTH_COOKIE } from "utils/constants";

const useAuth = () => {
  useEffect(() => {
    const token = getCookie(AUTH_COOKIE);

    if (!token) {
      window.open("/login", "_self");
    }
  }, []);
};

export default useAuth;
