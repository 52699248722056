import { post } from "./base";
import { getCookie, setCookie } from "typescript-cookie";
import history from "utils/history";
import { AUTH_COOKIE } from "utils/constants";

export const requestAuthCode = (phoneNumber: string, unixSeconds: number) => {
  return post("/request_auth_code", false, {
    phoneNumber,
    unixSeconds,
  });
};

export const authenticate = (
  phoneNumber: string,
  unixSeconds: number,
  authCode: string
) => {
  return post("/v1/auth", false, {
    phoneNumber,
    unixSeconds,
    authCode,
  }).subscribe(({ data }) => {
    setCookie(AUTH_COOKIE, data.authToken);
    window.open("/documents", "_self");
  });
};
