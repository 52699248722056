import { get, post } from "./base";

export interface Collection {
  id: string;
  title: string;
  description: string;
  trailerId?: string;
  gifUrl?: string;
}

export interface Coach {
  id: string;
  name: string;
  avatarUrl: string;
}

export interface Video {
  id: string;
  horizontalUrl: string;
  title: string;
}

export interface Share {
  id: string;
  collection?: Collection;
  videoUrl: string;
}

export const getShare = (id: string) => {
  return get(`/shares/${id}`);
};

export const signupForWaitlist = (id: string, phoneNumber: string) => {
  return post(`/shares/${id}/waitlist`, false, { phoneNumber });
};
