import { uploadDocument } from "api/apex";
import { useState } from "react";

const useDocumentUploadPage = () => {
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFileSelect = (val: File) => setFile(val);

  const onBack = () => setFile(undefined);

  const onUpload = () => {
    setUploading(true);
    if (file) {
      uploadDocument(file).subscribe(() => {
        setUploading(false);
        setSuccess(true);
      });
    }
  };

  return { onFileSelect, file, onBack, onUpload, uploading, success };
};

export default useDocumentUploadPage;
